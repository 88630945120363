// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.label {
  display: block;
  margin-bottom: 0.5rem;
  color : var(--dark-label);
}
.label.bold{
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB","sourcesContent":["\n.label {\n  display: block;\n  margin-bottom: 0.5rem;\n  color : var(--dark-label);\n}\n.label.bold{\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.business-container {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding-left: 4px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
  cursor: pointer;
}

.business-container:hover {
  border: 1px solid #1890ff;
}

.logo {
  height: 40px;
  margin: 16px;
}



.main-menu:not(.ant-menu-inline-collapsed) .ant-menu-item {
  display: flex;
  justify-content: start;
  align-items: center;
}

.main-menu:not(.ant-menu-inline-collapsed) .ant-menu-submenu-title {
  display: flex;
  justify-content: start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/layout/mainlayout.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;;;AAIA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".trigger {\n  padding: 0 24px;\n  font-size: 18px;\n  line-height: 64px;\n  cursor: pointer;\n  transition: color 0.3s;\n}\n\n.trigger:hover {\n  color: #1890ff;\n}\n\n.business-container {\n  display: flex;\n  border: 1px solid #ddd;\n  border-radius: 6px;\n  padding-left: 4px;\n  padding-right: 6px;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  align-items: center;\n  cursor: pointer;\n}\n\n.business-container:hover {\n  border: 1px solid #1890ff;\n}\n\n.logo {\n  height: 40px;\n  margin: 16px;\n}\n\n\n\n.main-menu:not(.ant-menu-inline-collapsed) .ant-menu-item {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n}\n\n.main-menu:not(.ant-menu-inline-collapsed) .ant-menu-submenu-title {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.free {
  color: blue;
  /* Change the color as needed */
}

.starter {
  color: orange;
}

.growth {
  color: #19AD79;
}

.proPlus {
  color: fuchsia;
}

.enterpriseElite {
  color: red;
}

.even-row {
  background-color: #f5f5f5;
  /* Optional: Add alternating row background colors */
}

.odd-row {
  background-color: #ffffff;
  /* Optional: Add alternating row background colors */
}

.price-feature {
  display: flex;
  justify-content: start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/pricing.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,oDAAoD;AACtD;;AAEA;EACE,yBAAyB;EACzB,oDAAoD;AACtD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".free {\n  color: blue;\n  /* Change the color as needed */\n}\n\n.starter {\n  color: orange;\n}\n\n.growth {\n  color: #19AD79;\n}\n\n.proPlus {\n  color: fuchsia;\n}\n\n.enterpriseElite {\n  color: red;\n}\n\n.even-row {\n  background-color: #f5f5f5;\n  /* Optional: Add alternating row background colors */\n}\n\n.odd-row {\n  background-color: #ffffff;\n  /* Optional: Add alternating row background colors */\n}\n\n.price-feature {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
